export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HASHTAG_REGEX = /^#[a-zA-Z0-9][a-zA-Z0-9\-_]*$/;

export default class Valid {
  public static cedulaValida(cedula: string): boolean | undefined {
    const isnum = /^\d+$/.test(cedula);
    if (cedula.length !== 10 || !isnum) {
      return false;
    } else if (cedula.length === 10) {
      const valores = [];
      for (let i = 0; i < 9; i++) {
        valores.push(+cedula[i] * (2 - (i % 2)));
      }

      let suma = 0;
      let valor = 0;
      valores.forEach(function(value) {
        if (value > 9) {
          valor = value - 9;
        } else {
          valor = value;
        }
        suma = suma + valor;
      });

      const digitoVerificador = suma % 10;

      if (+cedula[9] === 0) {
        if (+digitoVerificador === 0) {
          return true;
        } else {
          return false;
        }
      }

      if (+cedula[9] === 10 - +digitoVerificador) {
        return true;
      } else {
        return false;
      }
    }
  }

  public static rucValido(ruc: string): boolean | undefined {
    if (ruc.length !== 13) {
      return false;
    } else if (ruc.length === 13) {
      if (ruc.substring(10) === "001") {
        return true;
      } else {
        return false;
      }
    }
  }

  public static validarCedRuc(identificacion: string, tipo: number): boolean {
    let total = 0;
    const dictSerie = [
      [2, 1, 2, 1, 2, 1, 2, 1, 2],
      [3, 2, 7, 6, 5, 4, 3, 2],
      [4, 3, 2, 7, 6, 5, 4, 3, 2]
    ];
    const dictBase = [10, 11, 11];
    const dictDver = [
      +identificacion[9],
      +identificacion[8],
      +identificacion[9]
    ];

    for (let i = 0; i < dictSerie[tipo].length; i++) {
      const p = +identificacion[i] * dictSerie[tipo][i];
      if (tipo === 0) {
        if (p < 10) {
          total += p;
        } else {
          total += +String(p)[0] + +String(p)[1];
        }
      } else {
        total += p;
      }
    }
    //for i in range(0, len(multip)):

    const mod = total % dictBase[tipo];
    let val = 0;
    if (mod !== 0) {
      val = dictBase[tipo] - mod;
    }

    const ident = val === dictDver[tipo];
    return ident;
  }

  // Funcion para validar si la cedula o ruc ingresado es valido
  public static identificacionValida(
    identificacion: string
  ): boolean | undefined {
    if (identificacion.length === 10 || identificacion.length === 13) {
      const cp = identificacion.substring(0, 2); // Codigo de provincia
      if ((+cp >= 1 && +cp <= 24) || +cp === 30) {
        const tercerDigito = +identificacion[2];
        if (tercerDigito >= 0 && tercerDigito <= 6) {
          if (identificacion.length === 10) {
            return this.validarCedRuc(identificacion, 0);
          } else if (identificacion.length === 13) {
            return (
              this.validarCedRuc(identificacion, 0) &&
              identificacion.substring(10, 13) === "001"
            );
          } // se verifica q los ultimos numeros no sean 000
        } else if (tercerDigito === 6) {
          return this.validarCedRuc(identificacion, 1); // sociedades publicas
        } else if (tercerDigito === 9) {
          // si es ruc
          return this.validarCedRuc(identificacion, 2); // sociedades privadas
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
