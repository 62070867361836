import {
  DireccionEnvio,
  DireccionFacturacion
} from "@/store/modules/CartStore";
import httpClient from "../http-client";
import ProvinciaService from "./ProvinciaService";

const ENDPOINT = "/pedido";

export interface PedidoCreateDto {
  id?: number;
  items: any;
  direccionEnvio: DireccionEnvio;
  direccionFacturacion: DireccionFacturacion;
}

export interface PedidoDto {
  id: number;
}

export interface PedidoCreatedDto {
  id: number;
  displayTotal: string;
}

class PedidoService {
  /*
  getAll(): Promise<DistanciaCreateDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  */

  getProvincias() {
    return ProvinciaService.getProvincias();
  }

  crearPedido(createDto: PedidoCreateDto): Promise<PedidoDto> {
    return httpClient.post(`${ENDPOINT}/crear`, createDto).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getPedido(idPedido: string): Promise<PedidoCreatedDto> {
    return httpClient.get(`${ENDPOINT}/${idPedido}`).then(response => {
      if (response.data && response.data) {
        return response.data;
      }
    });
  }

  sendPapeleta(idPedido: string, createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/papeleta/${idPedido}`, createDto);
  }

  getPedidos(): Promise<PedidoCreatedDto[]> {
    return httpClient.get(`${ENDPOINT}`).then(response => {
      if (response.data && response.data) {
        return response.data;
      }
    });
  }

  getPapeleta(idPedido: any) {
    return httpClient.get(`${ENDPOINT}/papeleta/${idPedido}`).then(response => {
      if (response.data && response.data) {
        return response.data;
      }
    });
  }

  getPapeletaImageUrl(fileName: string): string {
    //const response = process.env.VUE_APP_API + ENDPOINT + "/" + fileName;
    if (fileName) {
      return `${process.env.VUE_APP_API}${ENDPOINT}/papeleta/image/${fileName}`;
    }
    return "";
  }

  accionPapeleta(idPedido: any, dto: any) {
    return httpClient.post(`${ENDPOINT}/papeleta/accion/${idPedido}`, dto);
  }

  getMisPedidos(): Promise<PedidoCreatedDto[]> {
    return httpClient.get(`${ENDPOINT}/persona/pedidos`).then(response => {
      if (response.data && response.data) {
        return response.data;
      }
    });
  }

  modificarMiPedido(dto: any) {
    return httpClient.post(`${ENDPOINT}/persona/modificar-pedido`, dto);
  }

  getPapeletasPendientesAprobar(): Promise<number> {
    return httpClient.get(`${ENDPOINT}/papeletas/contar`).then(response => {
      return response.data;
    });
  }

  // pago web tarjeta
  pagoWebTarjeta(idPedido: any, dto: any) {
    return httpClient.post(`${ENDPOINT}/pago-tarjeta/${idPedido}`, dto);
  }
}
export default new PedidoService();
