






































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      camisetas: [{ modelo: "", archivo: null }]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    },
    addForm() {
      this.camisetas.push({
        modelo: "",
        archivo: null
      });
    },
    removeForm(index: number) {
      this.camisetas.splice(index, 1);
    }
  }
});
