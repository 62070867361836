
























import Vue from "vue";
export default Vue.extend({
  props: ["recorridos"],
  data() {
    return {
      model: null
    };
  }
});
