






import Vue from "vue";
import CustomCarousel from "../../components/CustomCarousel.vue";
import CarrerasOrganizadas from "@/components/web/organizadas/CarrerasOrganizadas.vue";
import BannerService from "../../api/BannerService";
export default Vue.extend({
  components: {
    CustomCarousel,
    CarrerasOrganizadas
  },
  data() {
    return {
      banners: [] as string[]
    };
  },
  methods: {
    getBanners() {
      BannerService.getBanners()
        .then(response => {
          //this.banners = response.data;
          //console.log(this.banners);
          if (response.data) {
            for (const banner of response.data) {
              this.banners.push(this.getBanner(banner.fileName));
            }
          }
        })
        .catch(() => {
          //Bugsnag.notify("No se cargaron los banners");
        }); //fin get Banners
    },
    getBanner(fileName: string) {
      return BannerService.getBanner(fileName);
    }
  },
  mounted() {
    this.getBanners();
  }
});
