import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/recorrido";

export interface AuspicianteCarreraDto {
  url: string;
}

class AuspicianteCarreraService {
  getRecorridos(carreraID: string | number): Promise<any[]> {
    return httpClient.get(`${ENDPOINT}/${carreraID}`).then(response => {
      if (response && response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  private createRecorrido(carreraID: string | number, createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/${carreraID}`, createDto);
  }

  private updateRecorrido(
    carreraID: string | number,
    recorridoID: string | number,
    createDto: FormData
  ) {
    return httpClient.put(`${ENDPOINT}/${carreraID}/${recorridoID}`, createDto);
  }

  saveRecorrido(
    carreraID: string | number,
    recorridoID: string | number,
    dto: FormData
  ) {
    //
    if (recorridoID) {
      return this.updateRecorrido(carreraID, recorridoID, dto);
    } else {
      return this.createRecorrido(carreraID, dto);
    }
  }

  deleteRecorrido(carreraID: string | number, recorridoID: string | number) {
    return httpClient.delete(`${ENDPOINT}/${carreraID}/${recorridoID}`);
  }
}

export default new AuspicianteCarreraService();
