<template>
  <div>
    <v-carousel
      v-model="model"
      height="auto"
      cycle
      show-arrows-on-hover
      hide-delimiter-background
    >
      <template v-for="(item, i) in banners">
        <v-carousel-item
          :key="i"
          :src="getBanner(item.fileName)"
          to="/proximas"
        ></v-carousel-item>
      </template>
    </v-carousel>

    <router-link to="/proximas">
      <v-img src="/principal_vertical.jpg" class="mt-12"></v-img>
    </router-link>

    <v-container class="mt-5" v-if="false">
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="12" cols="auto">
          <h1 class="px-xl-5 px-lg-5">
            <strong
              ><span class="text-md-h3 text-sm-h4 text-h4">Quiénes</span>
              <p class="text-md-h3 text-sm-h4 text-h4">somos</p></strong
            >
          </h1>
          <div class="text-justify mt-4 px-lg-5 px-xl-5 text-body-1">
            <p>
              Somos un grupo con más de 15 años de experiencia en marketing,
              relaciones públicas, organización de eventos y producción
              audiovisual.
            </p>
            <p>
              Compartimos contenido y organizamos torneos y competencias para
              motivar a las personas a adoptar y mantener una vida activa y una
              alimentación equilibrada.
            </p>
          </div>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12" cols="12">
          <VideoGallery />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VideoGallery from "../../components/VideoGallery.vue";
import BannerService from "../../api/BannerService";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Inicio",
  components: {
    VideoGallery
  },
  data() {
    return {
      model: 0,
      banners: []
    };
  },
  methods: {
    getBanners() {
      BannerService.getBanners()
        .then(response => {
          this.banners = response.data;
          console.log(this.banners);
        })
        .catch(() => {
          Bugsnag.notify("No se cargaron los banners");
        }); //fin get Banners
    },
    getBanner(fileName) {
      return BannerService.getBanner(fileName);
    }
  },
  mounted() {
    this.getBanners();
  }
};
</script>

<style scoped>
.carousel-item {
  height: 100vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  background-color: grey;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
