









































import Vue from "vue";
import EncuestaService, {
  EncuestaCreateDto,
  PreguntaEncuestaCreateDto
} from "@/api/encuesta/EncuestaService";
import Formulario from "./Formulario.vue";
export default Vue.extend({
  components: {
    Formulario
  },
  props: ["dialog", "idEncuesta", "carrera"],
  data() {
    return {
      encuesta: {
        nombre: "",
        descripcion: "",
        fechaInicio: "",
        fechaFin: "",
        estado: "",
        preguntas: [] as PreguntaEncuestaCreateDto[]
      } as EncuestaCreateDto
    };
  },
  methods: {
    loadEncuesta() {
      EncuestaService.getEncuesta(this.idEncuesta)
        .then(response => {
          this.encuesta = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Encuesta",
            text: "No se pudo obtener"
          });
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    saved() {
      this.$emit("saved", "someValue");
      //this.close();
    }
  },
  mounted() {
    this.loadEncuesta();
  }
});
