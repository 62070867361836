export default class StringUtils {
  public static toCamelCase(value: string) {
    if (value) {
      const palabras = value.split(" ");
      let response = "";
      for (const p of palabras) {
        response +=
          p.charAt(0).toUpperCase() + p.toLocaleLowerCase().slice(1) + " ";
      }
      response = response.replace(" De ", " de ");
      response = response.replace(" Los ", " los ");
      return response.trim();
    }
    return "";
  }
}
