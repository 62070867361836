












































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      current: "jC-WGtlIrRQ",
      videos: ["px7vTh4PWt8", "clAU4N6yQ2w", "4_ASb1Qhdk0", "d9KuPs8gd0k"],
      model: 0,
      elements: 3
    };
  },
  methods: {
    getTotalIers() {
      const div = Math.ceil(this.videos.length / this.elements);
      const iters = [];
      for (let i = 0; i < div; i++) {
        iters.push(i);
      }
      return iters;
    },
    getVideosSlide(iter: number) {
      const videos = this.videos.filter(
        (value: string, index: number) =>
          Math.trunc(index / this.elements) === iter
      );
      const len = videos.length;
      if (len < this.elements) {
        for (let i = 0; i < this.elements - len; i++) {
          videos.push(this.videos[i]);
        }
      }
      return videos;
    },
    onVideoClick(video: string) {
      this.videos.push(this.current);
      this.current = video;
      this.videos = this.videos.filter((value: string) => value !== video);
    }
  }
});
