<template>
  <v-card class="mx-2 my-2 flex d-flex flex-column" elevation="4">
    <router-link :to="'/organizadas/' + card.id">
      <v-img :src="foto" :aspect-ratio="16 / 9">
        <!--
      <v-img :src="card.src" :aspect-ratio="16 / 9">
            <v-card-title v-text="card.title"></v-card-title>
            -->
      </v-img>
    </router-link>

    <v-card-title style="color: #5a897d;" class="text-center">{{
      card.nombre || "Carrera"
    }}</v-card-title>
    <!--
    <v-card-subtitle class="content-text">
      {{ "" }}
    </v-card-subtitle>
    -->
    <v-card-text class="flex">
      <v-row v-if="true" align="center" class="mx-0 my-1">
        <div class="ml-1 content-text black--text">
          <v-icon medium color="#5a897d">mdi-calendar</v-icon>
          Inicia el {{ formatDate(card.fechaInicioEvento) }}
        </div>
      </v-row>
      <v-row v-if="true" align="center" class="mx-0 my-1">
        <div class="ml-1 content-text black--text">
          <v-icon medium color="#5a897d">mdi-calendar</v-icon>
          Termina el {{ formatDate(card.fechaFinEvento) }}
        </div>
      </v-row>
      <!--
      <v-row v-if="true" align="center" class="mx-0 my-1">
        <v-icon medium color="blue-grey darken-2">mdi-clock</v-icon>
        <div class="ml-1 content-text">{{ hour || "hora" }}</div>
      </v-row>
      -->
      <v-row v-if="true" align="center" class="mx-0 my-1">
        <div class="ml-1 content-text black--text">
          <v-icon medium color="#5a897d">mdi-map</v-icon>
          {{ toCamelCase(card.ciudad) }} | {{ card.lugar }}
        </div>
      </v-row>
    </v-card-text>

    <v-divider v-if="true" class="mx-2"></v-divider>

    <v-card-text v-if="false">
      <v-chip-group active-class="deep-purple accent-4 white--text" column>
        <v-chip v-for="categoria in card.distancias" :key="categoria.id">{{
          categoria.nombre
        }}</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-card-actions>
      <v-btn block color="#5a897d" outlined :to="'/organizadas/' + card.id">
        Ver Carrera
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import BannerPublicitarioCarreraService from "@/api/carrera/detalle/BannerPublicitarioCarreraService";
import CartStore from "@/store/modules/CartStore";
import AuthStore from "@/store/modules/AuthStore";
import StringUtils from "@/utils/StringUtils";
import DateUtils from "@/utils/DateUtils";

export default {
  components: {},
  props: ["card", "isLoggedIn"],
  data() {
    return {
      foto: "",
      dialog: false,
      //distancias: [],
      tallas: [], // yup
      categorias: [], // yup
      precios: [], // yup
      distancias: []
    };
  },
  methods: {
    decimalToHour(value) {
      return DateUtils.decimalToHourDisplay(value);
    },
    toCamelCase(value) {
      return StringUtils.toCamelCase(value);
    },
    formatDate(date) {
      moment.locale("es");
      const resp = moment(date).format(
        //"dddd d \\d\\e MMMM \\d\\e YYYY"
        "DD/MMMM/YYYY - HH:mm"
      );
      return resp;
    },
    loadImagen() {
      const found = this.card.bannersPublicitarios.find(
        element => element.name === "vistaPrevia"
      );
      if (found) {
        this.foto = BannerPublicitarioCarreraService.getImageUrl(
          found.fileName
        );
      }
    },
    dialogChange(value) {
      this.dialog = value;
    },
    hacerAlgo() {
      //
    },
    getHora(hora) {
      const h = hora.toFixed(2);
      return h;
    },
    addToCart(value) {
      console.log(value);
      CartStore.addToCart(value);
      this.$router.push("/cart");
    },
    loadCategorias() {
      //AuthStore.getPersonaSexo
      //AuthStore.getPersonaEdad
      const categorias = [];
      for (const v of this.card.categorias) {
        if (
          v.edadMinima > AuthStore.getPersonaEdad ||
          v.edadMaxima < AuthStore.getPersonaEdad
        ) {
          continue; // ignorados por no cumplir
        }
        if (
          v.tipoParticipante === "T" ||
          AuthStore.getPersonaSexo === v.tipoParticipante
        ) {
          categorias.push(v);
        }
      } //fin for
      this.categorias = categorias;
    }, //fin funcion
    loadPrecios() {
      //
      const precios = [];
      for (const v of this.card.preciosCarreras) {
        //tercera
        if (v.tipoDescriptor === "11" && AuthStore.isPersonaInMembrecia) {
          precios.push(v);
        } else if (v.tipoDescriptor === "4" && AuthStore.getPersonaEdad >= 65) {
          precios.push(v);
          //discapacitad
        } else if (v.tipoDescriptor === "2" && AuthStore.getCarnetConadis) {
          precios.push(v);
        } else if (v.tipoDescriptor === "3" && AuthStore.getPersonaEdad < 12) {
          precios.push(v);
        } else if (v.tipoDescriptor === "1") {
          precios.push(v);
        }
      }
      precios.sort((a, b) => a.precio - b.precio);
      this.precios = precios;
    },
    loadTallas() {
      //
      const tallas = [];
      for (const v of this.card.tallasCamisetas) {
        //if (v.articuloInventario.stock > 0) {
        if (v.articuloInventario.stockAvailable > 0) {
          tallas.push(v);
        }
      }
      this.tallas = tallas;
    },
    loadDistancias() {
      const distancias = [];
      for (const v of this.card.distancias) {
        if (
          v.edadMinima > AuthStore.getPersonaEdad ||
          v.edadMaxima < AuthStore.getPersonaEdad
        ) {
          continue; // ignorados por no cumplir
        } else {
          distancias.push(v);
        }
      } //fin for
      this.distancias = distancias;
    }
  },
  mounted() {
    this.loadImagen();
    //this.loadPrecios(); ahora es por distancias los precios
    this.loadCategorias();
    this.loadTallas();
    this.loadDistancias();
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
