






















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      cuentas: [],
      rules: [(v: number[]) => v.length > 0 || "Campo requerido"],
      accounts: [
        { title: "Banco del Pacífico - Cuenta Ahorros # 1052492249", id: 1 }
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    }
  }
});
