






















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      lugares: [],
      rules: [(v: number[]) => v.length > 0 || "Campo requerido"],
      places: [
        { title: "Registro Online", id: 1 },
        { title: "Taurus Fitness Center", id: 2 },
        { title: "Stand Keep Running Ec", id: 3 },
        { title: "Brinko Clubs", id: 4 },
        { title: "Fitness Inc", id: 5 },
        { title: "Flex Gym", id: 6 },
        { title: "Quicentro Shopping", id: 7 },
        { title: "Oficina Guayaquil", id: 8 },
        { title: "Silvio Guerra Sports", id: 9 },
        { title: "Feria Deportiva", id: 10 },
        { title: "enZúmbate", id: 11 },
        { title: "Wink", id: 12 },
        { title: "Natural Vitality City Mall", id: 13 },
        { title: "Natural Vitality Mall del Sur", id: 14 },
        { title: "Natural Vitality Plaza Quil", id: 15 },
        { title: "Natural Vitality Village Plaza", id: 16 },
        { title: "Natural Vitality Mall del Sol", id: 17 },
        { title: "Natural Vitality Kennedy Norte", id: 18 },
        { title: "Guayaquil Tenis Club Centro", id: 19 },
        { title: "Guayaquil Tenis Club Anexo Samborond&oacute;n", id: 20 },
        { title: "DP WORLD", id: 21 },
        { title: "MALL DEL SOL", id: 22 }
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    }
  }
});
