










































































import Vue from "vue";
import ArchivoAdd from "./archivo/ArchivoAdd.vue";
export default Vue.extend({
  props: ["preguntas", "pregunta", "i"],
  components: {
    ArchivoAdd
  },
  data() {
    return {
      dialog: false,
      rgRespuesta: null,
      entrada: "",
      archivosCargados: []
      //archivosPendientes: []
    };
  },
  methods: {
    siguientePregunta(i: number, pregunta: any, respuesta: any) {
      //
      const obDto = {
        detalle: {
          pregunta: { id: pregunta.id, texto: pregunta.texto },
          respuesta: { id: respuesta.id, texto: respuesta.texto },
          //entrada: this.entrada
          adjuntos: this.archivosCargados
        },
        i: i
      };
      this.$emit("siguientePregunta", obDto);
    },
    enviarRespuesta(pregunta: any, respuesta: any) {
      this.$emit("enviarFormulario", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto },
        //entrada: this.entrada
        adjuntos: this.archivosCargados
      });
    },
    saveRespuesta(pregunta: any, respuesta: any, adjuntos: any) {
      this.$emit("saveRespuesta", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto },
        adjuntos: adjuntos
      });
    },
    // otros
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    archivosSubidos(dto: any) {
      //
      this.archivosCargados = [];
      this.archivosCargados = dto;
    }
  },
  mounted() {
    this.rgRespuesta = this.pregunta.respuestas[0];
  },
  watch: {
    archivosCargados: function(newVal) {
      if (newVal && this.rgRespuesta) {
        this.saveRespuesta(this.pregunta, this.rgRespuesta, newVal);
      }
    }
  }
});
