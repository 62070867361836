






































import Vue from "vue";
export default Vue.extend({
  props: ["recorridos"],
  data() {
    return {
      model: null
    };
  },
  methods: {
    getNextImg(index: number) {
      const len = this.recorridos.length;
      const i = (index + 1) % len;
      return this.recorridos[i];
    },
    getPrevImg(index: number) {
      const len = this.recorridos.length;
      const i = (index + len - 1) % len;
      return this.recorridos[i];
    }
  }
});
