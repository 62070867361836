


























































































import EncuestaService from "@/api/encuesta/EncuestaService";
import Vue from "vue";
import PreguntaTipoOption from "./pregunta/PreguntaTipoOption.vue";
import PreguntaTipoCampo from "./pregunta/PreguntaTipoCampo.vue";
import PreguntaTipoArchivo from "./pregunta/PreguntaTipoArchivo.vue";
import AuthStore from "@/store/modules/AuthStore";

export default Vue.extend({
  props: ["encuesta", "carrera"],
  components: {
    PreguntaTipoOption,
    PreguntaTipoCampo,
    PreguntaTipoArchivo
  },
  data() {
    return {
      e1: 1,
      respuestas: [] as any[],
      rgRespuesta: null
    };
  },
  methods: {
    // ligar respuestas del option
    /*
    addRespuesta(pregunta: any, respuesta: any) {
      //
      this.respuestas.push({
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto }
      });
    },
    siguientePregunta(i: number, pregunta: any, respuesta: any) {
      this.addRespuesta(pregunta, respuesta);
      this.rgRespuesta = null;
      this.next(i);
    },
    // subir el formulario
    enviarRespuesta(pregunta: any, respuesta: any) {
      const detalles = [
        ...this.respuestas,
        {
          pregunta: { id: pregunta.id, texto: pregunta.texto },
          respuesta: { id: respuesta.id, texto: respuesta.texto }
        }
      ];
      const newDto = {
        detalles,
        idEvento: this.carrera.id
        //encuestado: this.encuestado
      };
      this.save(newDto);
    },
    */
    next(i: number) {
      this.e1 = i + 2;
    },
    addDetalle(detalleDto: any) {
      this.respuestas.push(detalleDto);
    },
    siguientePregunta(obDto: any) {
      this.addDetalle(obDto.detalle);
      this.next(obDto.i);
    },
    enviarFormulario(detalleDto: any) {
      const detalles = [...this.respuestas, detalleDto];
      const newDto = {
        detalles,
        idEvento: this.carrera.id
      };
      this.save(newDto);
    },
    saveRespuesta(dto: any) {
      for (let index = 0; index < this.respuestas.length; index++) {
        if (this.respuestas[index].pregunta.id === dto.pregunta.id) {
          this.respuestas.splice(index, 1);
          break;
        }
      }
      this.respuestas.push(dto);
      this.respuestas.sort(function(a, b) {
        return a.pregunta.id - b.pregunta.id;
      });
    },
    saveFormulario() {
      //
      const detalles = [];
      for (const pregunta of this.preguntasObligatorias) {
        for (const dtoDetalle of this.respuestas) {
          if (pregunta.id === dtoDetalle.pregunta.id) {
            detalles.push(dtoDetalle);
            break;
          }
        }
      }

      if (detalles && detalles.length > 0) {
        const newDto = {
          detalles,
          idEvento: this.carrera.id
        };
        this.save(newDto);
      }
    },
    save(createDto: any) {
      EncuestaService.saveFormulario(this.encuesta.id, createDto)
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Formulario",
            text: "¡Ha sido enviado con éxito!"
          }).then(() => {
            this.saved();
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Respuesta",
            text: "No se pudo enviar"
          });
        });
    },
    saved() {
      this.$emit("saved", "someValue");
    }
  },
  computed: {
    preguntasObligatorias() {
      // cuando es el id 1 o 2
      if (
        this.encuesta.id === 2 &&
        (AuthStore.getPersonaEdad >= 65 || AuthStore.getCarnetConadis)
      ) {
        const preguntas = [];
        for (const pregunta of this.encuesta.preguntas) {
          if (pregunta.tipo === "4") {
            preguntas.push(pregunta);
          }
        }
        return preguntas;
      } else if (this.encuesta.id === 1 || this.encuesta.id === 2) {
        //
        const preguntas = [];
        for (const pregunta of this.encuesta.preguntas) {
          //
          if (pregunta.tipo !== "4") {
            preguntas.push(pregunta);
          } else if (pregunta.tipo === "4") {
            // start tipo 4
            let contadorSI = 0;

            for (const detalle of this.respuestas) {
              if (detalle.respuesta.texto === "Sí") {
                contadorSI++;
                break;
              }
            } // fin del for

            /*
            if (this.respuestas.length<) {
              for (let x = 0; x < this.respuestas.length; x++) {
                const detalle = this.respuestas[x];
                if (detalle.respuesta.texto !== "No") {
                  contadorSI++;
                  break;
                }
              }
            }
            */

            if (contadorSI > 0) {
              preguntas.push(pregunta);
            }
          } // end tipo 4
        }
        return preguntas;
      }
      // cuando no es el id 1 o 2
      else {
        return this.encuesta.preguntas;
      }
    },
    canSend() {
      if (
        this.preguntasObligatorias &&
        this.respuestas &&
        this.respuestas.length >= this.preguntasObligatorias.length
      ) {
        return true;
      }
      return false;
    }
  }
});
