import AuthService, {
  AuthCredentialsDto,
  PersonaCreateDto
} from "@/api/AuthService";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";

import store from "../index";

const userData = localStorage.getItem("user");
const user = userData ? JSON.parse(userData) : null;

export interface CartItem {
  id: number;
  tallaCamiseta: number;
  categoriaDistancia: number;
  personalizacion: string;
  imagen: string;
  precio: {
    id: number;
    precio: number;
  };
  // temporal
  carrera: any;
  // nuevo
  cuponEvento: string;
}

export interface DireccionBase {
  provincia: string;

  canton: string; //ya no es ciudad

  direccion: string;

  // Persona

  tipoIdentificacion: string;

  identificacion: string;

  nombre: string;

  apellido: string;

  telefono: string;
}
export interface DireccionEnvio extends DireccionBase {
  id?: number;
  ubicacion: string; // url del mapa
  referencia: string;
}

export interface DireccionFacturacion extends DireccionBase {
  id?: number;
}

@Module({
  namespaced: true,
  name: "CartStore",
  store: store,
  dynamic: true
})
class CartStore extends VuexModule {
  public loggedIn = user ? true : false;
  public user = user ? user : null;

  public direccionEnvio = {
    provincia: "",
    canton: "",
    direccion: "",
    tipoIdentificacion: "CED",
    identificacion: "",
    nombre: "",
    apellido: "",
    telefono: "",
    //nuevo
    ubicacion: "",
    referencia: ""
  };
  public direccionFacturacion = {
    provincia: "",
    canton: "",
    direccion: "",
    tipoIdentificacion: "",
    identificacion: "",
    nombre: "",
    apellido: "",
    telefono: ""
  };

  public items: CartItem[] = [];

  @Mutation
  direccionEnvioCambio(direccion: DireccionEnvio) {
    this.direccionEnvio = direccion;
  }

  @Mutation
  direccionFacturacionCambio(direccion: DireccionFacturacion) {
    this.direccionFacturacion = direccion;
  }

  @Mutation
  loginSuccess(user: unknown) {
    this.loggedIn = true;
    this.user = user;
  }

  @Mutation
  loginFailure() {
    this.loggedIn = false;
    this.user = null;
  }

  @Mutation
  logoutSuccess() {
    this.loggedIn = false;
    this.user = null;
  }

  // cart items
  @Mutation
  itemsCleared() {
    //this.items = [];
    this.items.splice(0);
  }

  @Mutation
  itemAdded(item: CartItem) {
    /*
    for (const doc of this.items) {
      if (doc.id === item.id) {
        return;
      }
    }
    */
    this.items.splice(0);
    this.items.push(item);
  }

  @Mutation
  itemRemoved(item: CartItem) {
    const index = this.items.findIndex(value => value.id === item.id);
    if (index === -1) return null;
    return this.items.splice(index, 1);
  }

  @Action
  public addToCart(item: CartItem) {
    this.context.commit("itemAdded", item);
  }

  @Action
  public removeFromCart(item: CartItem) {
    this.context.commit("itemRemoved", item);
  }

  @Action
  public cleanCart() {
    this.context.commit("itemsCleared");
  }

  @Action
  public login(authCredentialsDto: AuthCredentialsDto) {
    console.log(authCredentialsDto);
    return AuthService.login(authCredentialsDto).then(
      user => {
        this.context.commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        this.context.commit("loginFailure");
        return Promise.reject(error);
      }
    );
  }

  @Action
  public register(createDto: PersonaCreateDto) {
    console.log(createDto);
    return AuthService.signup(createDto).then(
      response => {
        return Promise.resolve(response.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  @Action
  public actualizarDireccionEnvio(direccion: DireccionEnvio) {
    this.context.commit("direccionEnvioCambio", direccion);
  }

  @Action
  public actualizarDireccionFacturacion(direccion: DireccionFacturacion) {
    this.context.commit("direccionFacturacionCambio", direccion);
  }

  @Action
  public logout() {
    AuthService.logout();
    this.context.commit("logoutSuccess");
  }

  get getLoggedIn(): boolean {
    return this.loggedIn;
  }

  get getRole(): string {
    return this.user.role; // devuelve un string el numero del rol
  }

  get getRoleDisplay(): string {
    return this.user.roleDisplay;
  }

  get getCartItems(): CartItem[] {
    return this.items;
  }

  get getCartNecesitaEnvio(): boolean {
    let respuesta = false;
    for (const item of this.items) {
      if (item.carrera.modalidad === "V") {
        respuesta = true;
        break;
      }
    }
    return respuesta;
  }

  get getCartTotal(): number {
    let total = 0;
    for (const item of this.items) {
      total += item.precio.precio;
    }
    return total;
  }

  get getDireccionEnvio(): DireccionEnvio {
    return this.direccionEnvio;
  }

  get getDireccionFacturacion(): DireccionFacturacion {
    return this.direccionFacturacion;
  }
}

export default getModule(CartStore);
