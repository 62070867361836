

















































import MainInfoVue from "@/components/admin/carreras/MainInfo.vue";
import Banners from "@/components/admin/carreras/Banners.vue";
import Prices from "@/components/admin/carreras/Prices.vue";
import Kits from "@/components/admin/carreras/Kits.vue";
import InscriptionPoints from "@/components/admin/carreras/InscriptionPoints.vue";
import BankAccount from "@/components/admin/carreras/BankAccount.vue";
import CommitData from "@/components/admin/carreras/CommitData.vue";
import ShirtSize from "@/components/admin/carreras/ShirtSize.vue";
import PersonalShirt from "@/components/admin/carreras/PersonalShirt.vue";
import Categories from "@/components/admin/carreras/Categories.vue";
import Sponsors from "@/components/admin/carreras/Sponsors.vue";
import Vue from "vue";

type FormValidate = {
  validate: () => boolean;
  isFormComplete: () => boolean;
};

export default Vue.extend({
  data() {
    return {
      e6: 1,
      snackbar: false,
      tipo: "Carrera",
      color: "pink",
      text: "Existen errores en el formulario",
      sections: [
        {
          title: "Información principal",
          component: MainInfoVue,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Banners y publicidad",
          component: Banners,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Precios",
          component: Prices,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Entrega de kits",
          component: Kits,
          valid: true,
          complete: false,
          isEvent: false
        },
        {
          title: "Puntos de inscripción",
          component: InscriptionPoints,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Cuenta bancaria",
          component: BankAccount,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Datos confirmación",
          component: CommitData,
          valid: true,
          complete: false,
          isEvent: true
        },
        {
          title: "Tallas de camisetas",
          component: ShirtSize,
          valid: true,
          complete: false,
          isEvent: false
        },
        {
          title: "Camisetas personalizadas",
          component: PersonalShirt,
          valid: true,
          complete: false,
          isEvent: false
        },
        {
          title: "Categorías",
          component: Categories,
          valid: true,
          complete: false,
          isEvent: false
        },
        {
          title: "Auspiciantes",
          component: Sponsors,
          valid: true,
          complete: false,
          isEvent: true
        }
      ]
    };
  },
  methods: {
    checkStatus(i: number) {
      const form = this.$refs["form" + i] as [Vue & FormValidate];
      if (form[0].validate()) {
        this.sections[i].valid = true;
        this.sections[i].complete = true;
      } else {
        this.sections[i].valid = false;
        this.sections[i].complete = false;
      }
      return this.sections[i].complete && this.sections[i].valid;
    },
    nextAndCheckStatus(i: number) {
      this.checkStatus(i);
      this.e6 = i + 2;
    },
    checkStatusAndSave() {
      let status = true;
      for (let i = 0; i < this.sections.length; i++) {
        status = status && this.checkStatus(i);
      }
      if (status) {
        this.text = "Enviado con éxito";
        this.color = "green";
        this.snackbar = true;
      } else {
        this.text = "Existen errores en el formulario";
        this.color = "red";
        this.snackbar = true;
      }
    },
    onSelect(value: string) {
      this.tipo = value;
    }
  }
});
