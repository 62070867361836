




































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      forms: [
        {
          nombre: "",
          link: "",
          orden: "",
          archivo: null
        }
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    },
    addForm() {
      this.forms.push({
        nombre: "",
        link: "",
        orden: "",
        archivo: null
      });
    },
    removeForm(index: number) {
      this.forms.splice(index, 1);
    }
  }
});
