


























































































































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      banner: null,
      bannerRes: null,
      principal: null,
      previa: null,
      camiseta: null,
      kit: null,
      categorias: null,
      publicidad: null,
      linkBanner: "",
      rules: [(v: File) => v != null || "Campo requerido"]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    },
    getImage(file: File) {
      return URL.createObjectURL(file);
    }
  }
});
