























import { EMAIL_REGEX } from "@/utils/valid";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      telefono: "",
      correo: "",
      rules: [(v: string) => !!v || "Campo requerido"],
      emailRules: [
        (v: string) => !!v || "Campo requerido",
        (v: string) => EMAIL_REGEX.test(v) || "El email no es válido"
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    }
  }
});
