

































import Vue from "vue";
import EncuestaByID from "./EncuestaByID.vue";
export default Vue.extend({
  props: ["card", "carrera", "encuesta"],
  components: {
    EncuestaByID
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    saved() {
      this.dialogChange(false);
      this.$emit("saved", "someValue");
    }
  }
});
