










import Vue from "vue";
import Menu from "../../components/Menu.vue";
import Footer from "../../components/Footer.vue";
export default Vue.extend({
  components: {
    Menu,
    Footer
  }
});
