










import Vue from "vue";
import Menu from "@/components/admin/Menu.vue";
export default Vue.extend({
  components: {
    Menu
  }
});
