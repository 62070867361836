

























import Vue from "vue";
import CustomCarousel from "../../components/CustomCarousel.vue";
import GridEvent from "../../components/GridEvent.vue";
export default Vue.extend({
  components: {
    CustomCarousel,
    GridEvent
  },
  data() {
    return {
      items: [
        "https://keeprunning.ec/img/banners/section/banner_otros2_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_otros3_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_otros4_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_otros5_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_otros1_03.jpg"
      ],
      page: 0,
      index: 1,
      page2: 0,
      index2: 1,
      initialDataEvents: [
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_08.jpg",
          title: "Inauguración Juegos Deportivos Grupo Vilaseca 2019",
          day: "Sábado 29 de Junio - 2019",
          url: "https://keeprunning.ec/otroseventos/8"
        }
      ],
      initialDataActivaciones: [
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_013.jpg",
          title: "Ciclón",
          subtitle: "Quita las manchas, vive el color",
          day: " Domingo 23 de Junio - 2019",
          location: "Running Day Circus Edition"
        }
      ],
      events: [
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_08.jpg",
          title: "Inauguración Juegos Deportivos Grupo Vilaseca 2019",
          day: "Sábado 29 de Junio - 2019",
          url: "https://keeprunning.ec/otroseventos/8"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_020.jpg",
          title: "Enzúmbate Live Fest",
          day: "Miércoles 21 de Noviembre - 2018",
          url: "https://keeprunning.ec/carreraresult/58"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_021.jpg",
          title: "Guayaquil Deportivo 2018",
          day: "Sábado 6 de Octubre - 2018",
          url: "https://keeprunning.ec/carreraresult/58"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_022.jpg",
          title: "Feria Guayaquil Deportivo 2019",
          day: "Domingo 10 de Noviembre - 2019",
          url: "https://keeprunning.ec/carreraresult/58"
        }
      ],
      activaciones: [
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_013.jpg",
          title: "Ciclón",
          subtitle: "Quita las manchas, vive el color",
          day: " Domingo 23 de Junio - 2019",
          location: "Running Day Circus Edition"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_020.jpg",
          title: "Menticol",
          subtitle: "Zona Cool",
          day: " Domingo 28 de Abril - 2019",
          location: "Corro por la Tierra"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_015.jpg",
          title: "Nutrit",
          subtitle: "Protege, humecta y repara",
          day: "Domingo 28 de Abril - 2019",
          location: "Corro por la Tierra"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_016.jpg",
          title: "GoFit",
          subtitle: "Proteína lista para tomar",
          day: "Domingo 22 de Julio - 2018",
          location: "Guayaquil Run 2018"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_018.jpg",
          title: "Pringles",
          subtitle: "Halloween Night",
          day: " Sábado 27 de Octubre - 2018",
          location: "Running Night Halloween Edition"
        },
        {
          img: "https://keeprunning.ec/img/eventos/principal/principal_019.jpg",
          title: "Leche Toni Light",
          subtitle: "Revive el sabor de tu infancia",
          day: " Sábado 27 de Octubre - 2018",
          location: "Running Night Halloween Edition"
        }
      ]
    };
  },
  methods: {
    getInitialDataEvents() {
      const events = [];
      let i = this.page;
      for (; i < 6 * this.index; i++) {
        if (i < this.events.length) {
          events.push(this.events[i]);
        }
      }
      this.page = i;
      this.index++;
      return events;
    },
    getInitialDataActi() {
      const events = [];
      let i = this.page2;
      for (; i < 6 * this.index2; i++) {
        if (i < this.activaciones.length) {
          events.push(this.activaciones[i]);
        }
      }
      this.page2 = i;
      this.index2++;
      return events;
    }
  },
  beforeMount() {
    this.initialDataEvents = this.getInitialDataEvents();
    this.initialDataActivaciones = this.getInitialDataActi();
  }
});
