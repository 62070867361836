























































import Vue from "vue";
import EncuestaService from "@/api/encuesta/EncuestaService";

//import moment from "moment";
export default Vue.extend({
  components: {},
  props: ["dialog"],
  data() {
    return {
      valid: true,
      //file: null,
      files: [],
      editedIndex: -1,
      /*
      editedItem: {
        id: 0
      },
      */
      imageRules: [
        (value: File) =>
          !value ||
          value.size < 5 * 1024 * 1024 ||
          "Archivo debe pesar menos de 5 MB!"
      ]
    };
  },
  created() {
    //this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    validate() {
      //console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.save();
      }
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const formData = new FormData();
      //formData.append("image", this.files);
      for (let i = 0; i < this.files.length; i++) {
        formData.append("image", this.files[i]);
      }
      EncuestaService.uploadAdjunto(formData)
        .then(response => {
          this.close();
          if (response && response.data) {
            this.$emit("saved", response.data);
          }
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Imagen",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Imagen",
            text: "No se pudo guardar"
          });
        });
    }
  }
});
