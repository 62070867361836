import httpClient from "./http-client";

const ENDPOINT = "/configuracion/banners";
//const getAll = () => httpClient.get(ENDPOINT);

export interface CreateBannerDto {
  nombre: string;
  url: string;
}

class BannerService {
  //post
  createBanner(createDto: FormData) {
    return httpClient.post(ENDPOINT, createDto);
  }

  getBanners() {
    return httpClient.get(ENDPOINT);
  }

  getBanner(fileName: string): string {
    const response = process.env.VUE_APP_API + ENDPOINT + "/" + fileName;
    return response;
  }

  deleteBanner(fileName: string) {
    return httpClient.delete(ENDPOINT + "/" + fileName);
  }
}

export default new BannerService();
