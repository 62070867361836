















































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      social: [
        {
          icon: "mdi-instagram",
          data: "@keeprunningec",
          link: "https://www.instagram.com/keeprunningec"
        },
        {
          icon: "mdi-facebook",
          data: "@keeprunningec",
          link: "https://www.facebook.com/keeprunningec/?"
        },
        {
          icon: "$stravaIcon",
          data: "@keeprunningec",
          link: "https://www.strava.com/clubs/keeprunningec"
        }
      ],
      contacto: [
        {
          icon: " mdi-whatsapp",
          data: "+593 961664116",
          link:
            "https://wa.me/593961664116?text=Hola%20Keep%20Running%20necesito%20ayuda"
        },
        {
          icon: "mdi-phone",
          data: "096-166-4116",
          link: "tel:+593961664116"
        },
        {
          icon: "mdi-email",
          data: "contactenos@keeprunning.ec",
          link: "mailto:contactenos@keeprunning.ec"
        }
      ],
      atencion: [
        {
          icon: "mdi-calendar",
          data: "Lunes a Viernes"
        },
        {
          icon: "mdi-clock",
          data: "09:00 a 17:00"
        }
      ]
    };
  }
});
