

























import Vue from "vue";
import EventCard from "./EventCard.vue";
import EventCartDto from "@/api/EventService";
import store, { StoreState } from "@/store";
export default Vue.extend({
  store: store,
  components: {
    EventCard
  },
  props: {
    url: String,
    initialData: {
      type: Array,
      default: () => []
    },
    loadMore: {
      type: Function,
      default: function() {
        return () => [];
      }
    }
  },
  data() {
    return {
      events: this.initialData
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        const position =
          document.documentElement.scrollTop + window.innerHeight;
        const bottomOfWindow =
          position === document.documentElement.offsetHeight;

        if (this.loadMore && bottomOfWindow) {
          const data = this.loadMore();
          for (const event in data) this.events.push(data[event]);
        }
      };
    },
    onEventCart(event: EventCartDto, isSelected: boolean) {
      if (isSelected) {
        this.$store.commit("addEventToCart", event);
      } else {
        this.$store.commit("removeEventToCart", event);
      }
    },
    inStore(id: number) {
      const cart = (this.$store.state as StoreState).cart;
      return cart.find(value => value.id === id) != null;
    }
  },
  mounted() {
    this.scroll();
  }
});
