








































































































import Vue from "vue";
import axios from "axios";
import AuthStore from "@/store/modules/AuthStore";
//import { Fragment } from "vue-fragment";
import PedidoService from "@/api/pedido/PedidoService";
import { isIn } from "class-validator";

export default Vue.extend({
  name: "App",

  components: {
    //HelloWorld
  },

  data: () => ({
    encuestasPendientes: 0,
    papeletasPendientes: 0,
    titulo: null,
    url: null,
    drawer: true,
    items: [
      { title: "Dashboard", icon: "mdi-home", url: "/admin" },
      {
        title: "Carreras",
        icon: "mdi-calendar-clock",
        url: "/admin/addevent",
        roles: ["1", "2"],
        items: [
          {
            title: "Listado",
            icon: "mdi-walk",
            url: "/admin/evento/carrera",
            roles: ["1", "2"]
          },
          {
            title: "Tallas",
            icon: "mdi-hanger",
            url: "/admin/evento/talla",
            roles: ["1"]
          },
          {
            title: "Distancias",
            icon: "mdi-map-marker-distance",
            url: "/admin/evento/distancia",
            roles: ["1"]
          }
          /*
          {
            title: "Carreras old",
            icon: "mdi-database",
            url: "/admin/addevent"
          }
          */
        ]
      },
      {
        title: "Personas",
        icon: "mdi-account-group",
        url: "/admin/persona",
        roles: ["1", "2"]
      },
      /*
      {
        title: "Inventario",
        icon: "mdi-shopping",
        url: "/admin/inventario"
      },
      */
      {
        title: "Encuestas Event",
        icon: "mdi-book-information-variant",
        url: "/admin/encuestas-eventos",
        roles: ["1"]
      },
      {
        title: "Papeletas",
        icon: "mdi-cash-multiple",
        url: "/admin/transacciones",
        roles: ["1"]
      },
      {
        title: "Paymentez CC",
        icon: "mdi-credit-card",
        url: "/admin/paymentez/transactions",
        roles: ["1"]
      },
      //{ title: "Accesos", icon: "mdi-account-box", url: "/admin/accesos" },
      {
        title: "Configuración",
        icon: "mdi-database",
        roles: ["1"],
        items: [
          {
            title: "Home Banners",
            icon: "mdi-view-carousel",
            url: "/admin/banners",
            roles: ["1"]
          },
          {
            title: "Home Videos",
            icon: "mdi-youtube",
            url: "/admin/home-video",
            roles: ["1"]
          },
          {
            title: "Cuenta Bancaria",
            icon: "mdi-bank",
            url: "/admin/cuenta-bancaria",
            roles: ["1"]
          }
        ]
      }
      /*
      { title: "Catalogos", icon: "mdi-view-dashboard", url: "/" },
      { title: "Transacciones", icon: "mdi-cash-multiple", url: "/" },
      { title: "Reportes", icon: "mdi-gavel", url: "/" },
      { title: "Accesos", icon: "mdi-account-box", url: "/" }
      */
    ],
    intervalContarPedidos: 0
    //
  }),
  mounted() {
    console.log(`La api es: ${process.env.VUE_APP_API}`);
    this.url = process.env.VUE_APP_API;
    axios
      .get(process.env.VUE_APP_API + "/")
      .then(response => (this.titulo = response.data));
    this.contarPedidosPendientes();
  },
  beforeDestroy() {
    clearInterval(this.intervalContarPedidos);
  },
  methods: {
    cerrarSesion() {
      AuthStore.logout();
      this.$router.push("/").catch(() => {
        console.log("Ruta activa");
      });
    },
    contarPedidosPendientes() {
      this.intervalContarPedidos = setInterval(() => {
        // inicio axios
        PedidoService.getPapeletasPendientesAprobar()
          .then(response => {
            this.papeletasPendientes = response;
            //console.log(this.papeletasPendientes);
          })
          .catch(() => {
            console.log("no se pudo contar los recibos pendientes");
          });
        // fin axios
      }, 4000);
    },

    isInRole(roles: string[]) {
      return isIn(this.getRole, roles);
    }
  },
  computed: {
    getRoleDisplay() {
      return AuthStore.getRoleDisplay;
    },
    getRole(): string {
      if (AuthStore.getLoggedIn && AuthStore.getRole) {
        return AuthStore.getRole;
      } else {
        return "";
      }
    }
  }
});
