




































































import Vue from "vue";
type size = {
  title: string;
  value: string;
  status: boolean;
  stock: string;
};
export default Vue.extend({
  data() {
    return {
      i: 0,
      isComplete: false,
      tallas: [],
      fecha: new Date().toISOString().substr(0, 10),
      menu: false,
      rules: [(v: number[]) => v.length > 0 || "Campo requerido"],
      rulesSize: [
        (v: string) => !!v || "Campo rquerido",
        (v: string) => parseInt(v) > 0 || "Ingrese un stock válido"
      ],
      dateRules: [
        (v: string) => !!v || "La fecha es requerida",
        (v: string) =>
          new Date(v) >= new Date() || "La fecha debe ser posterior a la actual"
      ],
      sizes: [
        { title: "XX Small", value: "xxs" },
        { title: "X Small", value: "xs" },
        { title: "Small", value: "s" },
        { title: "Medium", value: "m" },
        { title: "Large", value: "l" },
        { title: "Extra Large", value: "xl" },
        { title: "XX Large", value: "xxl" },
        {
          title: "Niños de 2 a 4 años",
          value: "2-4"
        },
        {
          title: "Niños de 5 a 7 años",
          value: "5-7"
        },
        {
          title: "Niños de 8 a 10 años",
          value: "8-10"
        },
        {
          title: "Niños de 11 a 13 años",
          value: "11-13"
        },
        { title: "EXTRA L", value: "exl" },
        { title: "EXTRA S", value: "exs" },
        { title: "XXX LARGE", value: "xxxl" }
      ]
    };
  },
  mounted() {
    for (const i in this.sizes) {
      (this.tallas as size[]).push({
        ...this.sizes[i],
        status: false,
        stock: "0"
      });
    }
  },
  computed: {
    total() {
      let value = 0;
      const data = this.tallas as size[];
      for (const i in data) {
        value += parseInt(data[i].stock);
      }
      return value;
    }
  },
  methods: {
    validate() {
      this.generateDataStock();
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    },
    getTitle(value: string) {
      return this.sizes.find(val => val.value === value)?.title;
    },
    generateDataStock() {
      return {};
    }
  }
});
