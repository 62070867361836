import httpClient from "../http-client";

const ENDPOINT = "/evento/cupon";

export interface CreateCuponEventoDto {
  prefijo: string;
  cantidad: number;
  porcentajeDescuento: number;
  motivo: string;
}

export interface CreateCuponEventoResponseDto {
  lote: string;
  motivo: string;
  cantidad: number;
  porcentajeDescuento: number;
  cupones: GetCuponEvento[];
}

export interface GetCuponEvento {
  id: number;
  cupon: {
    // cupon
    id: number;
    codigo: string;
  };
}

class CuponEventoService {
  getAll(idEvento: string | number): Promise<GetCuponEvento[]> {
    return httpClient.get(`${ENDPOINT}/${idEvento}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  crear(
    idEvento: string | number,
    createDto: CreateCuponEventoDto
  ): Promise<CreateCuponEventoResponseDto> {
    return httpClient
      .post(`${ENDPOINT}/${idEvento}`, createDto)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  validar(dto: { eventoID?: number; carreraID?: number; codigo: string }) {
    return httpClient.post(`${ENDPOINT}/validar`, dto);
  }
}
export default new CuponEventoService();
