















































import Vue from "vue";
export default Vue.extend({
  props: ["preguntas", "pregunta", "i"],
  data() {
    return {
      rgRespuesta: null,
      entrada: ""
    };
  },
  methods: {
    siguientePregunta(i: number, pregunta: any, respuesta: any) {
      //
      const obDto = {
        detalle: {
          pregunta: { id: pregunta.id, texto: pregunta.texto },
          respuesta: { id: respuesta.id, texto: respuesta.texto },
          entrada: this.entrada
        },
        i: i
      };
      this.$emit("siguientePregunta", obDto);
    },
    enviarRespuesta(pregunta: any, respuesta: any) {
      this.$emit("enviarFormulario", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto },
        entrada: this.entrada
      });
    },
    saveRespuesta(pregunta: any, respuesta: any, entrada: string) {
      this.$emit("saveRespuesta", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto },
        entrada: entrada
      });
    }
  },
  mounted() {
    this.rgRespuesta = this.pregunta.respuestas[0];
  },
  watch: {
    entrada: function(newVal) {
      if (newVal && this.rgRespuesta) {
        this.saveRespuesta(this.pregunta, this.rgRespuesta, newVal);
      }
    }
  }
});
