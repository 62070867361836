import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Meta from "vue-meta";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Fragment from "vue-fragment";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import * as VueGoogleMaps from "vue2-google-maps";

import { VueMasonryPlugin } from "vue-masonry";

Bugsnag.start({
  apiKey: "YOUR_API_KEY",
  enabledReleaseStages: [
    "production"
    //"development"
  ],
  plugins: [new BugsnagPluginVue()]
});

Vue.use(Meta);
Vue.use(Fragment.Plugin);
Vue.use(VueSweetalert2);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAM1AkoXBSs896m5-Nil8R_NoT8MtYUoMA"
  },
  installComponents: true
});
Vue.use(VueMasonryPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
