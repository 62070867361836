import EventCartDto from "@/api/EventService";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export type StoreState = {
  cart: EventCartDto[];
};

export default new Vuex.Store<StoreState>({
  state: {
    cart: []
  },
  mutations: {
    addEventToCart(state: StoreState, event: EventCartDto) {
      return state.cart.push(event);
    },
    removeEventToCart(state: StoreState, event: EventCartDto) {
      const index = state.cart.findIndex(value => value.id === event.id);
      if (index === -1) return null;
      return state.cart.splice(index, 1);
    }
  },
  actions: {},
  modules: {}
});
