var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Lugar","rules":_vm.rules}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4","md":"4","sm":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.fecha,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fecha=$event},"update:return-value":function($event){_vm.fecha=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.dateRules},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.fecha)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4","md":"4","sm":"12"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.desde,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.desde=$event},"update:return-value":function($event){_vm.desde=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"from",attrs:{"label":"Desde","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":_vm.timeFromRules},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":"","allowed-minutes":[0]},on:{"click:minute":function($event){return _vm.$refs.menu2.save(_vm.desde)}},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4","xl":"4","md":"4","sm":"12"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.hasta,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.hasta=$event},"update:return-value":function($event){_vm.hasta=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":_vm.timeToRules},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{ref:"to",attrs:{"full-width":"","allowed-minutes":[0]},on:{"click:minute":function($event){return _vm.$refs.menu3.save(_vm.hasta)}},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }