<template>
  <v-container fluid>
    <v-row dense>
      <v-col
        v-for="card in cards"
        :key="card.title"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="d-flex flex-column"
      >
        <CarreraCard :card="card" :isLoggedIn="isLoggin" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarreraCard from "./CarreraCard";
import CarreraService from "@/api/CarreraService";
import AuthStore from "@/store/modules/AuthStore";
export default {
  components: {
    CarreraCard
  },
  data: () => ({
    cards: []
  }),
  methods: {
    getAll() {
      CarreraService.getProximasCarreras()
        .then(response => {
          this.cards = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Carreras",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  computed: {
    isLoggin() {
      return AuthStore.getLoggedIn;
    }
  }
};
</script>
