






import Vue from "vue";
//import HelloWorld from "./components/HelloWorld.vue";
export default Vue.extend({
  name: "App",

  components: {
    //HelloWorld
  },

  metaInfo: {
    title: "Keep Running EC"
  },

  data: () => ({
    //
  }),
  mounted() {
    console.log(process.env.NODE_ENV);
  }
});
