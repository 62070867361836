
























































































































































































































import store, { StoreState } from "@/store";
import AuthStore from "@/store/modules/AuthStore";
import CartStore from "@/store/modules/CartStore";
import Vue from "vue";

interface MenuItem {
  title: string;
  menuItems: [];
  slug: string;
  path: string;
}

export default Vue.extend({
  name: "Menu",
  store: store,

  data: () => ({
    categoria: false,
    sidebar: false,
    menuItems: [
      { title: "Home", path: "/home", icon: "mdi-home" },
      { title: "Sign Up", path: "/signup", icon: "mdi-face" },
      { title: "Sign In", path: "/signin", icon: "mdi-lock-open" }
    ],

    categorias: [
      { title: "Inicio", path: "/inicio" },
      { title: "Próximas Carreras", path: "/proximas" },
      { title: "Carreras Organizadas", path: "/organizadas" }
      //{ title: "Carreras Organizadas", path: "organizadas" }
      //{ title: "Otros Eventos", path: "otros" },
      //{ title: "Contáctenos", path: "contacto" }
    ]
  }),
  methods: {
    getRuta(item: MenuItem) {
      if (item.slug) {
        return { path: "catalogo", query: { categoria: item.slug } };
      } else {
        return item.path || "/";
      }
    },
    toRuta(item: MenuItem) {
      const ruta = this.getRuta(item);
      this.$router.push(ruta);
    },
    getEventsInCart() {
      //return (this.$store.state as StoreState).cart.length;
      return CartStore.getCartItems.length;
    },
    cerrarSesion() {
      AuthStore.logout();
      this.$router.push("/").catch(() => {
        console.log("Ruta activa");
      });
    }
  },
  computed: {
    menuCategorias() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.categorias;
      }
      return null;
    },
    isLoggedIn(): boolean {
      return AuthStore.getLoggedIn;
    },
    getPersonaNombre(): string {
      return AuthStore.getPersonaNombre.split(" ")[0];
    },
    isManager(): boolean {
      return AuthStore.getLoggedIn && AuthStore.getRole !== "0";
    }
  }
});
