




































































































































































































































import { HASHTAG_REGEX } from "@/utils/valid";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      tipo: "Carrera",
      donacion: false,
      afiliado: false,
      atletas: "Mixto(Hombres y Mujeres)",
      nombre: "",
      ciudad: "",
      lugar: "",
      fecha: new Date().toISOString().substr(0, 10),
      hora: null,
      hastag: "",
      fechaMax: new Date().toISOString().substr(0, 10),
      horaMax: null,
      estado: "Abierta",
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      estados: ["Abierta", "Cerrada"],
      ciudades: ["Guayquil", "Manta", "Salinas", "Quito", "Otra"],
      tipoItems: ["Carrera", "Evento"],
      atletasItems: [
        "Mixto(Hombres y Mujeres)",
        "Solo Hombres",
        "Solo Mujeres"
      ],
      rules: [(v: string) => !!v || "Campo requerido"],
      dateRules: [
        (v: string) => !!v || "La fecha es requerida",
        (v: string) =>
          new Date(v) >= new Date() || "La fecha debe ser posterior a la actual"
      ],
      hashRules: [
        (v: string) => !!v || "El hashtag es requerido",
        (v: string) => HASHTAG_REGEX.test(v) || "El hashtag debe ser válido"
      ],
      boolItems: [
        {
          name: "Sí",
          value: true
        },
        {
          name: "No",
          value: false
        }
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return true;
    }
  },
  watch: {
    tipo(newTipo: string) {
      this.$emit("select", newTipo);
    }
  }
});
