














import Vue from "vue";
import RecorridoService from "@/api/carrera/detalle/RecorridoCarreraService";
import Carousel from "./recorrido/Carousel.vue";
import CarouselTriple from "./recorrido/CarouselTriple.vue";
export default Vue.extend({
  components: {
    Carousel,
    CarouselTriple
  },
  data() {
    return {
      model: 0,
      datos: [] as any[]
    };
  },
  methods: {
    loadRecorridos() {
      RecorridoService.getRecorridos(this.$route.params.id)
        .then(response => {
          //
          this.datos = response;
        })
        .catch(() => {
          //
        });
    }
  },
  computed: {
    recorridos(): any[] {
      const result = this.datos.filter(item => item.tipo === "1");
      return result;
    },
    lugares(): any[] {
      const result = this.datos.filter(item => item.tipo === "2");
      return result;
    },
    mapas(): any[] {
      const result = this.datos.filter(item => item.tipo === "3");
      return result;
    }
  },
  mounted() {
    this.loadRecorridos();
  }
});
