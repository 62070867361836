















































































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      fecha: new Date().toISOString().substr(0, 10),
      desde: "0:00",
      hasta: "0:00",
      menu: false,
      menu2: false,
      menu3: false,
      dateRules: [
        (v: string) => !!v || "La fecha es requerida",
        (v: string) =>
          new Date(v) >= new Date() || "La fecha debe ser posterior a la actual"
      ],
      rules: [(v: string) => !!v || "Campo requerido"],
      timeFromRules: [
        (v: string) => !!v || "Campo requerido"
        // (v: string) =>
        //   parseInt(v.split(":")[0]) <
        //     parseInt(
        //       (this.$refs.to as Vue & { value: string })?.value?.split(":")[0]
        //     ) || "La hora desde debe ser anterior a la hora hasta"
      ],
      timeToRules: [
        (v: string) => !!v || "Campo requerido"
        // (v: string) =>
        //   parseInt(v.split(":")[0]) >
        //     parseInt(
        //       (this.$refs.from as Vue & { value: string })?.value?.split(":")[0]
        //     ) || "La hora hasta debe ser posterior a la hora desde"
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    }
  }
});
