import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera/auspiciante";

export interface AuspicianteCarreraDto {
  url: string;
}

class AuspicianteCarreraService {
  //post
  editBanner(idBanner: string, createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/${idBanner}`, createDto);
  }

  getBanners(idCarrera: string) {
    return httpClient.get(`${ENDPOINT}/${idCarrera}`);
  }

  getImageUrl(fileName: string): string {
    //const response = process.env.VUE_APP_API + ENDPOINT + "/" + fileName;
    if (fileName) {
      return `${process.env.VUE_APP_API}${ENDPOINT}/image/${fileName}`;
    }
    return "";
  }

  deleteAuspiciante(idBanner: number) {
    return httpClient.delete(`${ENDPOINT}/${idBanner}`);
  }
}

export default new AuspicianteCarreraService();
