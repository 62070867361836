






















































import Vue from "vue";
import EventCartDto from "@/api/EventService";
export default Vue.extend({
  props: {
    id: Number,
    img: String,
    title: String,
    subtitle: String,
    day: String,
    hour: String,
    location: String,
    href: String,
    distances: Array,
    selected: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      selection:
        this.distances && this.distances.length > 0 ? this.distances[0] : 0,
      isSelected: this.selected
    };
  },
  methods: {
    add() {
      if (this.selection != null) {
        this.loading = true;
        this.isSelected = !this.isSelected;
        const event: EventCartDto = {
          id: this.id,
          title: this.title,
          subtitle: this.subtitle,
          img: this.img,
          day: this.day,
          hour: this.hour,
          location: this.location,
          distance: this.selection
        };
        this.$emit("onCart", event, this.isSelected);
        this.loading = false;
      }
    }
  }
});
