









































import Vue from "vue";
export default Vue.extend({
  props: ["preguntas", "pregunta", "i"],
  data() {
    return {
      rgRespuesta: null
    };
  },
  methods: {
    siguientePregunta(i: number, pregunta: any, respuesta: any) {
      //
      const obDto = {
        detalle: {
          pregunta: { id: pregunta.id, texto: pregunta.texto },
          respuesta: { id: respuesta.id, texto: respuesta.texto }
        },
        i: i
      };
      this.$emit("siguientePregunta", obDto);
    },
    enviarRespuesta(pregunta: any, respuesta: any) {
      this.$emit("enviarFormulario", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto }
      });
    },
    saveRespuesta(pregunta: any, respuesta: any) {
      this.$emit("saveRespuesta", {
        pregunta: { id: pregunta.id, texto: pregunta.texto },
        respuesta: { id: respuesta.id, texto: respuesta.texto }
      });
    }
  },
  computed: {},
  watch: {
    rgRespuesta: function(newVal) {
      if (newVal) {
        this.saveRespuesta(this.pregunta, newVal);
      }
    }
  }
});
