export default class DateUtils {
  public static decimalToHourDisplay(value: number) {
    if (value) {
      const hours = Math.floor(value);
      const minutes = Math.floor(value * 100 - hours * 100);
      const displayHours = hours < 10 ? `0${hours}` : `${hours}`;
      const displayMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      return `${displayHours}:${displayMinutes}`;
    } else {
      return "00:00";
    }
  }
}
