

























































































































































































































































































































//import AuthStore from "@/store/modules/AuthStore";
import Vue from "vue";
//import CarreraService from "@/api/CarreraService";
import AuthStore from "@/store/modules/AuthStore";
import CarreraService from "@/api/CarreraService";
import CuponEventoService from "@/api/evento/CuponEventoService";
import EncuestaService, {
  GetMisEncuestasByEvento
} from "@/api/encuesta/EncuestaService";
//import moment from "moment";
import EncuestaCard from "./encuesta/EncuestaCard.vue";
import { AxiosError, AxiosResponse } from "axios";
export default Vue.extend({
  props: [
    "dialog",
    "dato",
    "imagen",
    "tallas",
    "categorias",
    //"precio", ahora el precio va ligado a las distancias
    "distancias"
  ],
  components: { EncuestaCard },
  data() {
    return {
      isLoading: false,
      cuponEvento: "",
      // encuestas inicio
      card: {
        src:
          "https://opinionpublica.uvm.mx/sites/default/files/backgrounds/sociales_4.jpg"
      },
      canBuy: false,
      encuestas: {
        aprobadas: [],
        enviadas: [],
        nuevas: [],
        encuestas: [],
        ready: false
      } as GetMisEncuestasByEvento,
      // encuestas fin
      contarRegistros: -1,
      id: 0,
      distancia: {
        id: 0,
        nombre: "",
        precios: [] as {
          id: number;
          nombre: string;
          precio: number;
          tipoDescriptor: string;
        }[]
      },
      categoria: 0,
      talla: 0,
      personalizacion: "",
      TextRules: [(v: string) => !!v || "Campo no debe estar vacio"],
      PersonalizacionRules: [
        (v: string) => v.length <= 10 || "La logintud no debe ser mayor a 10",
        (v: string) =>
          !v || /^[a-z0-9 .#@_-]+$/i.test(v) || "Carácter no permitido"
      ],
      valid: true,
      precio: {
        id: 0,
        precio: 0,
        nombre: ""
      },
      precios: [] as {
        id: number;
        nombre: string;
        precio: number;
        tipoDescriptor: string;
      }[],
      categoriasByD: [] as any[],
      // nuevos campos modelo
      modeloCamiseta: ""
    };
  },
  created() {
    //this.editedItem = { ...this.dato };
  },
  mounted() {
    //this.selectCategoria();
    this.contarMisRegistros(this.dato.id);
    this.loadEncuestas();
  },
  methods: {
    contarMisRegistros(idRegistro: any) {
      CarreraService.contarMisRegistros(idRegistro)
        .then(response => {
          this.contarRegistros = response;
        })
        .catch(() => {
          console.log("No se pudo contar sus registros");
        });
    },
    /*
    selectCategoria() {
      if (this.categorias.length > 0) {
        this.categoria = this.categorias[0];
      }
    },
    */
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    validate() {
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.save();
      }
    },
    save() {
      let categoriaDisplay = "";
      for (const v of this.categorias) {
        if (v.id === this.categoria) {
          console.log(v);
          categoriaDisplay = v.displayCategoria;
        }
      }
      //console.log("cat: ", categoriaDisplay);
      const dto = {
        id: this.dato.id,
        precio: this.precio,
        distancia: this.distancia,
        personalizacion: this.personalizacion,
        talla: this.talla,
        categoria: this.categoria,
        carrera: this.dato,
        imagen: this.imagen,
        displayCategoria: categoriaDisplay,
        cuponEvento: this.cuponEvento
      };
      // validar cupon
      this.isLoading = true;
      if (dto.cuponEvento) {
        CuponEventoService.validar({
          carreraID: dto.carrera.id,
          codigo: dto.cuponEvento
        })
          .then((response: AxiosResponse) => {
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 2000,
              icon: "success",
              title: "Cupón",
              text: `es válido y se aplicara un ${response?.data?.cupon?.porcentajeDescuento}% de descuento`
            });
            this.dialogChange(false);
            this.$emit("saved", dto);
          })
          .catch((error: AxiosError) => {
            let mensaje = "No se puede utilizar";
            if (error.response?.data && error.response.data.message) {
              mensaje = error.response.data.message;
            }
            this.$swal({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: "Cupón",
              text: mensaje
            });
          });
      } else {
        this.dialogChange(false);
        this.$emit("saved", dto);
      }

      this.isLoading = false;
      //this.dialogChange(false);
      //this.$emit("saved", dto);
    },
    onChangeDistancia() {
      this.precio = { id: 0, precio: 0, nombre: "" };
      this.categoria = 0;
      this.loadPrecios();
      this.loadCategorias();
    },
    loadPrecios() {
      //
      const precios = [];
      if (AuthStore.loggedIn) {
        for (const v of this.distancia.precios) {
          //tercera
          if (v.tipoDescriptor === "11" && AuthStore.isPersonaInMembrecia) {
            precios.push(v);
          } else if (v.tipoDescriptor === "5") {
            precios.push(v);
          } else if (
            v.tipoDescriptor === "4" &&
            AuthStore.getPersonaEdad >= 65
          ) {
            precios.push(v);
          } else if (
            v.tipoDescriptor === "3" &&
            AuthStore.getPersonaEdad < 12
          ) {
            precios.push(v);
          } else if (v.tipoDescriptor === "2" && AuthStore.getCarnetConadis) {
            precios.push(v);
          } else if (v.tipoDescriptor === "1") {
            precios.push(v);
          }
        }
      }
      precios.sort((a, b) => a.precio - b.precio); // de menor a mayor precio
      this.precios = precios;
      if (this.precios.length > 0) {
        this.precio = this.precios[0];
      }
    },
    loadCategorias() {
      //
      const categorias = [];
      for (const v of this.categorias) {
        if (v.distanciaId == this.distancia.id) {
          categorias.push(v);
        }
      }
      categorias.sort((a, b) => a.edadMinima - b.edadMinima); // de menor a mayor edad minima
      this.categoriasByD = categorias;
      if (this.categoriasByD.length > 0) {
        this.categoria = this.categoriasByD[0];
      }
    },
    loadEncuestas() {
      if (this.dato.modalidad !== "PCV") {
        this.encuestas.ready = true;
        return;
      }
      EncuestaService.getMisEncuestasByEvento(this.dato.id)
        .then(response => {
          this.encuestas = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Encuestas",
            text: "No se pudo obtener"
          });
        });
    },
    savedForm() {
      this.loadEncuestas();
    },
    onChangeModeloCamiseta() {
      this.talla = 0;
    }
  },
  computed: {
    isAdmin() {
      return AuthStore.getLoggedIn && AuthStore.getRole !== "0";
    },
    modelosCamiseta(): string[] {
      const modelosCamiseta = new Set<string>();
      for (const talla of this.tallas) {
        modelosCamiseta.add(talla.modelo);
      }
      return Array.from(modelosCamiseta);
    },
    tallasCamiseta(): any[] {
      const result = this.tallas.filter(
        (item: any) => item.modelo === this.modeloCamiseta &&
                      (
                        AuthStore.getPersonaEdad >= item.talla.edadMinima &&
                        AuthStore.getPersonaEdad <= item.talla.edadMaxima
                      )
      );
      if (result) {
        result.sort(function(a: any, b: any) {
          return a.talla.position - b.talla.position;
        });
        return result;
      } else {
        return [];
      }
    }
  },
  watch: {
    personalizacion(newVal: string) {
      this.personalizacion = newVal.toUpperCase();
    }
  }
  //
});
