









import Vue from "vue";
import CustomCarousel from "../../components/CustomCarousel.vue";
import ProximosEventos from "@/components/web/proximo/ProximosEventos.vue";
import BannerService from "../../api/BannerService";
export default Vue.extend({
  components: {
    CustomCarousel,
    ProximosEventos
  },
  data() {
    return {
      items: [
        /*
        "https://keeprunning.ec/img/banners/section/banner_prox2_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_prox3_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_prox4_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_prox5_03.jpg",
        "https://keeprunning.ec/img/banners/section/banner_prox1_03.jpg"
        */
        "/banner.jpg"
      ],
      banners: [] as string[]
    };
  },
  methods: {
    getBanners() {
      BannerService.getBanners()
        .then(response => {
          //this.banners = response.data;
          //console.log(this.banners);
          if (response.data) {
            for (const banner of response.data) {
              this.banners.push(this.getBanner(banner.fileName));
            }
          }
        })
        .catch(() => {
          //Bugsnag.notify("No se cargaron los banners");
        }); //fin get Banners
    },
    getBanner(fileName: string) {
      return BannerService.getBanner(fileName);
    }
  },
  mounted() {
    this.getBanners();
  }
});
