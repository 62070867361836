
































































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      isComplete: false,
      regPrecio: "",
      regMostrar: false,
      regObs: "",
      disPrecio: "",
      disMostrar: false,
      disObs: "",
      otros: [
        {
          desc: "",
          precio: "",
          id: "",
          obs: "",
          mostrar: false
        }
      ],
      rules: [(v: string) => !!v || "Campo requerido"]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    },
    addForm() {
      this.otros.push({
        desc: "",
        precio: "",
        id: "",
        obs: "",
        mostrar: false
      });
    },
    removeForm(index: number) {
      this.otros.splice(index, 1);
    }
  }
});
