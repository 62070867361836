













import Vue from "vue";
type tree = {
  id: number;
  name: string;
  children?: tree[];
};

export default Vue.extend({
  mounted() {
    let i = 0;
    for (const d in this.distancias) {
      const dist = this.distancias[d];
      const data: tree = {
        id: i++,
        name: dist.distancia,
        children: []
      };
      for (const c in dist.categoria) {
        const cate = dist.categoria[c];
        const more: tree = {
          id: i++,
          name: cate.nombre,
          children: []
        };
        for (const e in cate.edades) {
          const edad = cate.edades[e];
          const last: tree = {
            id: i++,
            name: edad.edad
          };
          more.children?.push(last);
        }
        data.children?.push(more);
      }
      (this.items as tree[]).push(data);
    }
  },
  data() {
    return {
      isComplete: false,
      items: [],
      selected: [],
      distancias: [
        {
          distancia: "15K",
          id: "1",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        {
          distancia: "10K",
          id: "2",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        {
          distancia: "5K",
          id: "3",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "hasta 19 años", id: "7" },
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        {
          distancia: "5K Brinko",
          status: false,
          id: "4",
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "5K Discapacidades",
          status: false,
          id: "49",
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "3K",
          id: "52",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "1K",
          id: "53",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] },
            { status: false, nombre: "Familiar", edades: [] }
          ]
        },
        {
          distancia: "1K Madre e Hija",
          id: "72",
          status: false,
          categoria: [{ status: false, nombre: "General femenido", edades: [] }]
        },
        {
          distancia: "1K Caminata Familiar",
          id: "77",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "5K Vilaseca",
          id: "90",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "Hasta 12 años", id: "93" },
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" },
                { status: false, edad: "5 a 19 años", id: "143" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "Hasta 12 años", id: "93" },
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" },
                { status: false, edad: "5 a 19 años", id: "143" }
              ]
            }
          ]
        },
        {
          distancia: "1K Vilaseca",
          id: "137",
          status: false,
          categoria: []
        },
        {
          distancia: "5K SamboRunDon",
          id: "144",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "13 a 14 años", id: "93" },
                { status: false, edad: "13 a 14 años", id: "95" },
                { status: false, edad: "15 a 16 años", id: "99" },
                { status: false, edad: "17 a 18 años", id: "102" },
                { status: false, edad: "19 a 32 años", id: "106" },
                { status: false, edad: "33 años en adelante", id: "110" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "13 a 14 años", id: "93" },
                { status: false, edad: "13 a 14 años", id: "95" },
                { status: false, edad: "15 a 16 años", id: "99" },
                { status: false, edad: "17 a 18 años", id: "102" },
                { status: false, edad: "19 a 32 años", id: "106" },
                { status: false, edad: "33 años en adelante", id: "110" }
              ]
            }
          ]
        },
        {
          distancia: "3K SamboRunDon",
          id: "147",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "9 a 10 años", id: "93" },
                { status: false, edad: "11 a 12 años", id: "95" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "9 a 10 años", id: "93" },
                { status: false, edad: "11 a 12 años", id: "95" }
              ]
            }
          ]
        },
        {
          distancia: "1K SamboRunDon",
          id: "150",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "5 a 6 años", id: "93" },
                { status: false, edad: "7 a 8 años", id: "95" },
                { status: false, edad: "2 a 4 años", id: "99" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "5 a 6 años", id: "93" },
                { status: false, edad: "7 a 8 años", id: "95" },
                { status: false, edad: "2 a 4 años", id: "99" }
              ]
            }
          ]
        },
        {
          distancia: "5K Olimpiadas GTC",
          id: "181",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "Infantil 5 a 6 años", id: "199" },
                { status: false, edad: "Juvenil 13 a 14 años", id: "205" },
                { status: false, edad: "Juvenil 15 a 16 años", id: "206" },
                { status: false, edad: "Juvenil 17 a 18 años", id: "207" },
                { status: false, edad: "Senior 19 a 32 años", id: "208" },
                { status: false, edad: "Master 33 a 39 años", id: "209" },
                { status: false, edad: "Master 40 a 49 años", id: "210" },
                { status: false, edad: "Master 50 a 59 años", id: "211" },
                { status: false, edad: "Master 60 años en adelante", id: "212" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "Master 40 a 49 años", id: "213" }
              ]
            }
          ]
        },
        {
          distancia: "3K Olimpiadas GTC",
          id: "184",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "Infantil 9 a 10 años", id: "199" },
                { status: false, edad: "Juvenil 11 a 12 años", id: "205" }
              ]
            }
          ]
        },
        {
          distancia: "1K Olimpiadas GTC",
          id: "187",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "Infantil 5 a 6 años", id: "199" },
                { status: false, edad: "Infantil 7 a 9 años", id: "205" }
              ]
            }
          ]
        },
        {
          distancia: "5K SamboRUNdon",
          id: "190",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            }
          ]
        },
        {
          distancia: "3K SamboRUNdon",
          id: "193",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "1K SamboRUNdon",
          id: "196",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "Infantil 5 a 6 años",
          id: "200",
          status: false,
          categoria: []
        },
        {
          distancia: "10K",
          id: "230",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            }
          ]
        },
        {
          distancia: "5K",
          id: "233",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "13 a 19 años", id: "95" },
                { status: false, edad: "20 a 29 años", id: "99" },
                { status: false, edad: "30 a 39 años", id: "102" },
                { status: false, edad: "40 a 49 años", id: "106" },
                { status: false, edad: "50 a 59 años", id: "108" },
                { status: false, edad: "60 años en adelante", id: "110" }
              ]
            }
          ]
        },
        {
          distancia: "3K",
          id: "236",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "1K",
          id: "239",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "21K DP WORLD (INDIVIDUAL)",
          id: "266",
          status: false,
          categoria: [
            { status: false, nombre: "Masculino", edades: [] },
            { status: false, nombre: "Femenino", edades: [] }
          ]
        },
        {
          distancia: "21K DP WORLD (EQUIPO DE 2 PERSONAS)",
          id: "269",
          status: false,
          categoria: []
        },
        {
          distancia: "21K DP WORLD (EQUIPO DE 4 PERSONAS)",
          id: "272",
          status: false,
          categoria: []
        },
        {
          distancia: "3K",
          id: "275",
          status: false,
          categoria: [
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "9 a 10 años", id: "93" },
                { status: false, edad: "11 a 12 años", id: "95" }
              ]
            }
          ]
        },
        { distancia: "1K", id: "278", status: false, categoria: [] },
        { distancia: "3K", id: "283", status: false, categoria: [] },
        {
          distancia: "15K",
          id: "286",
          status: false,
          categoria: [
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        {
          distancia: "15K",
          id: "294",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        {
          distancia: "21K",
          id: "307",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" },
                { status: false, edad: "40 a 49 años", id: "10" },
                { status: false, edad: "50 a 59 años", id: "11" },
                { status: false, edad: "desde 60 años", id: "12" }
              ]
            }
          ]
        },
        { distancia: "21K", id: "320", status: false, categoria: [] },
        {
          distancia: "60K prueba",
          id: "323",
          status: false,
          categoria: [
            {
              nombre: "Masculino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" }
              ]
            },
            {
              nombre: "Femenino",
              status: false,
              edades: [
                { status: false, edad: "20 a 29 años", id: "8" },
                { status: false, edad: "30 a 39 años", id: "9" }
              ]
            }
          ]
        }
      ]
    };
  },
  methods: {
    validate() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (valid && this.selected.length > 0) {
        this.isComplete = true;
        return true;
      }
      this.isComplete = false;
      return false;
    },
    isFormComplete() {
      return this.isComplete;
    }
  }
});
