import httpClient from "../http-client";
//import { EncuestadoCreateDto } from "./EncuestadoService";

const ENDPOINT = "/catalogo/encuesta";

export interface EncuestaCreateDto {
  id?: number;
  nombre: string;
  descripcion: string;
  fechaInicio: string;
  fechaFin: string;
  estado: string;
  preguntas?: PreguntaEncuestaCreateDto[];
}

export interface GetEncuestaDto extends EncuestaCreateDto {
  id: number;
}

export interface PreguntaEncuestaCreateDto {
  id?: number;
  texto: string;
}

export interface RespuestaEncuestaCreateDto {
  id?: number;
  texto: string;
}

/*
export interface FormularioReadDto {
  encuestado: EncuestadoCreateDto;
}
*/
export interface GetMisEncuestasByEvento {
  aprobadas: GetEncuestaDto[];
  enviadas: GetEncuestaDto[];
  nuevas: GetEncuestaDto[];
  encuestas: GetEncuestaDto[];
  ready: boolean;
}

export interface GetFormularioEventoDto {
  id: number;
  //estado: string;
}

class EncuestaService {
  getMisEncuestasByEvento(idEvento: any): Promise<GetMisEncuestasByEvento> {
    return httpClient.get(`${ENDPOINT}/evento/${idEvento}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getAll(): Promise<GetEncuestaDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getEncuesta(id: string): Promise<GetEncuestaDto> {
    return httpClient.get(`${ENDPOINT}/${id}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  /*

  save(createDto: EncuestaCreateDto) {
    return httpClient.post(ENDPOINT, createDto);
  }

  savePregunta(idEncuesta: string, createDto: PreguntaEncuestaCreateDto) {
    return httpClient.post(`${ENDPOINT}/pregunta/${idEncuesta}`, createDto);
  }

  saveRespuesta(idPregunta: string, createDto: RespuestaEncuestaCreateDto) {
    return httpClient.post(`${ENDPOINT}/respuesta/${idPregunta}`, createDto);
  }

  */

  saveFormulario(idEncuesta: string, createDto: any) {
    return httpClient.post(`${ENDPOINT}/${idEncuesta}/formulario`, createDto);
  }

  uploadAdjunto(createDto: FormData) {
    return httpClient.post(`${ENDPOINT}/adjunto`, createDto);
  }

  getAllFormularioEvento(): Promise<GetFormularioEventoDto[]> {
    return httpClient.get(`${ENDPOINT}/formulario-evento`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  accionFormularioEvento(id: any, dto: any) {
    return httpClient.post(`${ENDPOINT}/formulario-evento/${id}/accion`, dto);
  }

  getAdjuntoUrl(fileName: string): string {
    //const response = process.env.VUE_APP_API + ENDPOINT + "/" + fileName;
    if (fileName) {
      return `${process.env.VUE_APP_API}${ENDPOINT}/adjunto/${fileName}`;
    }
    return "";
  }

  //
  /*
  getFormularios(id: string): Promise<FormularioReadDto[]> {
    return httpClient.get(`${ENDPOINT}/formularios/${id}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  */
}
export default new EncuestaService();
