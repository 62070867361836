//import axios ,{ AxiosRequestConfig } from 'axios';
import router from "@/router";
import axios from "axios";

/*
const userData = localStorage.getItem("user");
const user = userData ? JSON.parse(userData) : null;
let accessToken = "";
if (user && user.accessToken) {
  accessToken = `Bearer ${user.accessToken}`;
}
*/

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API
  /*
  headers: {
    Authorization: accessToken
  }
  */
});

/*
httpClient.interceptors.request.use(config => {
  const token = localStorage.getItem("jwt_token_kr");
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }
  config.headers = Object.assign(
    {
      Authorization: `Bearer ${token}`
    },
    config.headers
  );

  return config;
});
*/

const getAuthToken = () => localStorage.getItem("jwt_token_kr");

const authInterceptor = (config: any) => {
  config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
  config => config,
  async error => {
    if (!error.response) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    if (error.config.url === "auth/refresh") {
      // logout
      localStorage.removeItem("jwt_token_kr");
      localStorage.removeItem("jwt_refresh_kr");
      localStorage.removeItem("user");
      router.push("/login");
    }

    if (error.response.status === 403 || error.response.status === 401) {
      const token = localStorage.getItem("jwt_refresh_kr");
      const dto = { refreshToken: token };
      const res = await httpClient.post("auth/refresh", dto);
      const data = res.data;
      localStorage.setItem("jwt_token_kr", data.accessToken);
      // resend original request
      return await httpClient.request(error.config);
    }

    return new Promise((_, reject) => {
      reject(error);
    });
  }
);

/*
httpClient.interceptors.request.use(
  function(config) {
    const userData = localStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;
    if (user) {
      config.headers.Authorization = `Bearer ${user.accessToken}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
*/

export default httpClient;
