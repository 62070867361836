import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import stravaIcon from "../assets/custom_icons/strava.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      stravaIcon: {
        component: stravaIcon
      }
    }
  }
});
